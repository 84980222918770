import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import MenuShowHideIcon from 'ui/Icons/menu-show-hide.component.svg';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';
import { InvHeaderIcon } from 'ui/InvHeader/InvHeaderIcon';
import WhatsAppIcon from 'ui/Icons/whatsapp-alt.component.svg';
import LogoutIcon from 'ui/Icons/logout.component.svg';
import ContactUsIcon from 'ui/Icons/contact-us.component.svg';
import SettingsIcon from 'ui/Icons/settings.component.svg';
import { theme } from '../../../tailwind.config';
import { Link } from 'ui/Link';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export interface IMobileMenuDropdownProps {
  className?: string;
  openClassName?: string;
  itemsClassname?: string;
  itemCtaClassName?: string;
  fontClass?: string;
}

export const MobileMenuDropdown = (props: IMobileMenuDropdownProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const showWhatsAppIcon = !!dynamicParameters.HEADER_WHATSAPP;
  const showContactUsIcon = !!dynamicParameters.HEADER_CONTACT_FORM_URL;

  const { fontClass = 'font-pt-sans' } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { instanceClassname } = useHideOnClickOutside(setIsOpen, `menudropdown`);

  const handleMenudropdownClick = useCallback(
    e => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen]
  );

  return (
    <div
      tabIndex={0}
      className={classNames(
        `menudropdown-wrapper ${instanceClassname} ${props.className ? props.className : ''} ${
          isOpen ? props.openClassName : ''
        } menudropdown relative ${fontClass}}`
      )}
      onClick={handleMenudropdownClick}
    >
      <InvHeaderIcon
        className="inv-header-menu-icon cursor-pointer text-black"
        Icon={MenuShowHideIcon}
        iconClassName="o:w-[20px]"
        onClick={handleMenudropdownClick}
        tooltipText="Menu"
      />

      {isOpen && (
        <div
          className={`menudropdown-items flex flex-col gap-[5px] absolute min-w-full right-0 w-[210px] z-50 shadow-inv-shadow-md ${props.itemsClassname ||
            ''} ${isOpen ? 'is-open' : ''}`}
        >
            <Link to="settings" internalClassName={`flex items-center text-[15px] hover:text-black cursor-pointer ${props.itemCtaClassName || ''}`}>
                <SettingsIcon className={classNames("w-[32px]", '')} fill={theme.colors['black']} />
                Your Settings
            </Link>
            {showWhatsAppIcon && (
              <Link to={`https://wa.me/${dynamicParameters.HEADER_WHATSAPP}`} type="external" target="_blank" internalClassName={`flex items-center text-[15px] hover:text-black cursor-pointer ${props.itemCtaClassName || ''}`}>
                <WhatsAppIcon className={classNames("w-[32px]", '')} fill={theme.colors['black']} />
                Send WhatsApp
              </Link>
            )}
            {showContactUsIcon && (
              <Link to={dynamicParameters.HEADER_CONTACT_FORM_URL ?? ''} type="external" target="_blank" internalClassName={`flex items-center text-[15px] hover:text-black cursor-pointer ${props.itemCtaClassName || ''}`}>
                <ContactUsIcon className={classNames("w-[32px]", '')} fill={theme.colors['black']} />
                Contact-Us
              </Link>
            )}
            <div className="border-b border-solid border-gray-10"></div>
            <Link to="/logout" internalClassName={`flex items-center text-[15px] hover:text-black cursor-pointer ${props.itemCtaClassName || ''}`}>
                <LogoutIcon className={classNames("w-[32px]", '')} fill={theme.colors['black']} />
                Log-Out
            </Link>

        </div>
      )}
    </div>
  );
};
