import React, { useCallback, useState } from 'react';
import { Banner, EBannerType, getBannerIconByType } from 'ui/Banner/Banner';
import FluidButton from 'ui/FluidButton';
import { theme } from '../../../../../tailwind.config';
import CloseIcon from 'ui/Icons/close.component.svg';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { useNotifications } from 'hooks/useNotifications';
import { usePublishDirectoryEntryMutation } from 'services/CrmApi/mutations/usePublishDirectoryEntryMutation';
import { useDefaultOnErrorHandler } from 'hooks/useDefaultOnErrorHandler';
import { useQueryClient } from '@tanstack/react-query';

interface IErrorMessageProps {
  children: React.ReactNode;
}
const ErrorMessage: React.FC<IErrorMessageProps> = React.memo(({ children }) => (
  <p className="font-pt-sans text-[13px] flex items-center gap-[5px] leading-[15px] text-red-95 m-0">
    <CloseIcon style={{ width: '15px', height: '15px' }} fill={theme.colors['red-95']} />
    {children}
  </p>
));

interface ICrmPublishDirectoryEntryProps {
  className?: string; 
  directoryEntryId: string;
  messages: string[];
}

export const CrmPublishDirectoryEntry: React.FC<ICrmPublishDirectoryEntryProps> = React.memo(({ className, messages, directoryEntryId }) => {
  const type = messages.length > 0 ? EBannerType.WARNING : EBannerType.APPROVED;
  const icon = getBannerIconByType(type);
  const textMapping = {
    [EBannerType.APPROVED]: 'The Company is ready to be Published.',
    [EBannerType.WARNING]: 'Check missing requirements to Publish the company.',
  };
  const text = textMapping[type];

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const openConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);
  const queryClient = useQueryClient();
  const isLoading = queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntryId] }) > 0;
  const publishDirectoryEntryMutation = usePublishDirectoryEntryMutation();
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  
  const publishCompany = useCallback(async () => {
    try {
      await publishDirectoryEntryMutation.mutateAsync(directoryEntryId);
      showSuccessNotification('Travel Company published successfully.');
    } catch (error) {
      defaultOnErrorHandler(error, 'There was an error publishing the company.');
    } finally {
      queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      closeConfirmationModal();
    }
  }, [closeConfirmationModal, defaultOnErrorHandler, directoryEntryId, publishDirectoryEntryMutation, queryClient, showSuccessNotification]);

  return (
    <Banner type={type} className="flex flex-col gap-[10px]">
      <div className="header-line flex justify-between items-center gap-[20px]">
        <div className="header-line-message flex items-center">
          <img
            className="purchase-cost-cta-icon block file-icon mr-2"
            src={icon}
            width="28"
            height="28"
            alt={`${type} icon`}
          />
          <p className="font-pt-sans text-[16px] text-black m-0">{text}</p>
        </div>
        <FluidButton
          type="primary"
          isLoading={false}
          onClick={openConfirmationModal}
          disabled={isLoading || messages.length > 0}
        >
          Publish
        </FluidButton>
      </div>
      {messages.length > 0 && (
        <div className="publish-validation-messages flex flex-col gap-[8px]">
          {messages.map((message: string) => (
            <ErrorMessage key={message}>{message}</ErrorMessage>
          ))}
        </div>
      )}

      {confirmationModalOpen && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-publish-modal"
          title="Please confirm to publish the company. This action can not be undone."
          message="Are you sure?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={publishCompany}
          onCancel={closeConfirmationModal}
          isConfirmLoading={publishDirectoryEntryMutation.isPending}
        />
      )}
    </Banner>
  );
});
