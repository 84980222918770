import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames';
import { ILinkedDirectoryEntryArrayItem } from 'services/CrmApi/types/DirectoryEntry';
import { ETableSortColumn } from './types';
import { ESortOrder } from 'store/common/types';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { ContactLogo } from 'ui/ContactLogo';
import { Link } from 'ui/Link';
import { IScrollableTableColumn, IScrollableTableRow } from 'ui/ScrollableTable/types';
import { ScrollableTable } from 'ui/ScrollableTable/ScrollableTable';
import { useInplaceSorting } from '../../hooks/useInplaceSorting';
import { useNotifications } from 'hooks/useNotifications';
import { useUnlinkDirectoryEntriesMutation } from 'services/CrmApi/mutations/useUnlinkDirectoryEntriesMutation';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { useDefaultOnErrorHandler } from 'hooks/useDefaultOnErrorHandler';

interface ILinkedContactsListProps {
  contacts: ILinkedDirectoryEntryArrayItem[];
  onContactUnlink: () => void;
}

export const LinkedContactsList: React.FC<ILinkedContactsListProps> = React.memo(({ contacts, onContactUnlink }) => {
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const { sortBy, sortOrder, handleSort, sortedItems } = useInplaceSorting<ILinkedDirectoryEntryArrayItem>(ETableSortColumn.CREATED, ESortOrder.DESC, contacts);
  const unlinkDirectoryEntriesMutation = useUnlinkDirectoryEntriesMutation();
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const [removableLinkId, setRemovableLinkId] = useState<string|null>(null);
  const [contactsPrevCount, setContactsPrevCount] = useState(contacts.length);

  useEffect(() => {
    if (contacts.length > contactsPrevCount) {
      // When we add (and not remove) a link then reset the Sort
      handleSort();
    }
    setContactsPrevCount(contacts.length);
  }, [contacts, contactsPrevCount, handleSort]);

  const handleDeleteLinkClick = useCallback((linkId: string) => {
    setRemovableLinkId(linkId);
  }, []);

  const handleDeleteLinkCancel = useCallback(() => {
    setRemovableLinkId(null);
  }, []);
    
  const handleDeleteLinkConfirmation = useCallback(async () => {
    try {
      await unlinkDirectoryEntriesMutation.mutateAsync(removableLinkId || '');
      showSuccessNotification('Link removed');
      onContactUnlink();
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to remove link');
    } finally {
      setRemovableLinkId(null);
    }
  }, [defaultOnErrorHandler, onContactUnlink, removableLinkId, showSuccessNotification, unlinkDirectoryEntriesMutation]);    

  const generalColumnClassName = 'flex-1 text-gray-100 tracking-2xs text-xs leading-16px uppercase';

  const columnNameClassname = classnames('column-name', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  });

  const rowNameClassname = classnames('row-name overflow-hidden whitespace-nowrap text-ellipsis', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  });

  const columnEmailClassname = classnames('column-email', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  });

  const rowEmailClassname = classnames('row-email', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  })

  const linkClassname = 'font-pt-sans text-[15px]';

  const columns: IScrollableTableColumn[] = [
    {
      id: ETableSortColumn.NAME,
      label: 'NAME',
      className: columnNameClassname,
      fieldToSortBy: ETableSortColumn.NAME,
      isVisible: true,
    },
    {
      id: ETableSortColumn.TYPE,
      label: 'TYPE',
      className: generalColumnClassName,
      fieldToSortBy: ETableSortColumn.TYPE,
      isVisible: true,
    },
    {
      id: ETableSortColumn.EMAIL,
      label: 'E-MAIL',
      className: columnEmailClassname,
      fieldToSortBy: ETableSortColumn.EMAIL,
      isVisible: isDesktop || isDesktopHD,
    },
    {
      id: ETableSortColumn.ROLE,
      label: 'ROLE',
      className: generalColumnClassName,
      fieldToSortBy: ETableSortColumn.ROLE,
      isVisible: isTablet || isDesktop || isDesktopHD,
    },
    {
      id: 'actions',
      label: '',
      isVisible: true,
      className: 'item-actions flex items-center relative w-[68px] px-[10px]',
    }
  ];

  const rows: IScrollableTableRow[] = sortedItems.map(contact => {
    const [firstName, lastName] = contact.fullName.split(/\s+/);
    const name = (
      <div className={rowNameClassname} title={contact.fullName}>
        {(isDesktop || isDesktopHD) && <div className="contact-logo inline-flex max-w-[28px] h-auto mr-[10px] my-[2px]">
          <ContactLogo type="small" uuid={contact.id} firstName={firstName} lastName={lastName} sizePx='28px' />
        </div>}
        <Link internalClassName={linkClassname} to={`/crm/contact/${contact.id}/edit`} target='_blank'>{contact.fullName}</Link>
      </div>
    );
    const type = <span className="row-type flex-1">{contact.contactType?.value ?? ''}</span>;
    const email = <span className={columnEmailClassname}>{contact.email}</span>;
    const role = <span className="row-role flex-1 ">{contact.role?.value ?? ''}</span>;
    const actions = (
      <div className="item-actions flex items-center relative w-[48px]">
        <div className="w-4px h-34px rounded bg-brown-15 mx-[10px]" />
        <span className="w-6">
          <button
            className="delete rounded-full p-0 w-6 h-6 bg-white hover:bg-brown-40 border border-solid border-brown-80 cursor-pointer font-pt-sans"
            onClick={() => handleDeleteLinkClick(contact.linkId)}
          >
            <i className="fas fa-times text-brown-80"></i>
          </button>
        </span>
      </div>
    ); 

    return {
      id: contact.id,
      className: 'row text-black text-15px h-30px flex items-center pl-2 pr-6 mt-5px mb-5px',
      cells: [
        { id: 'name', value: name, className: rowNameClassname },
        { id: 'type', value: type, className: 'row-type flex-1' },
        { id: 'email', value: email, isVisible: isDesktop || isDesktopHD, className: rowEmailClassname },
        { id: 'role', value: role, isVisible: isTablet || isDesktop || isDesktopHD, className: 'row-role flex-1' },
        { id: 'actions', value: actions }
      ],
    };
  }); 

  return (
    <>
      {removableLinkId && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-import-modal"
          title="You are going to remove a relation. You can add it again later if you need to."
          message="Are you sure?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteLinkConfirmation}
          onCancel={handleDeleteLinkCancel}
          isConfirmLoading={false}
        />
      )}
      <ScrollableTable
        rows={rows}
        columns={columns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        messageWhenEmpty='No Results Found'
        onSort={handleSort}
        isLoading={false}
      />
    </>
  );
});
