import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ItineraryPage } from './Itinerary';

export interface ItineraryPageRoutingProps extends RouteComponentProps {}

export const ItineraryPageRouting = (props: ItineraryPageRoutingProps) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} component={ItineraryPage} exact />
    </Switch>
  );
};
