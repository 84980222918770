import React, { useCallback, useState } from 'react'
import { BorderWithLabel } from 'ui/BorderWithLabel/BorderWithLabel';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import classnames from 'classnames';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { Field, FieldArray, FieldArrayRenderProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { validateAddLeadForm } from './formValidation';
import { IAddLeadFormValues } from './types';
import { useNotifications } from 'hooks/useNotifications';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { ICreateLeadRequest, IUpdateLeadRequest } from 'services/CrmApi/types/CrmLeadTypes';
import { useCreateLeadMutation } from 'services/CrmApi/mutations/useCreateLeadMutation';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { createEmptyScoreLine } from './helpers';
import { NumberInput } from 'ui/NumberInput';
import { CircleIconButton } from 'ui/CircleIconButton';
import { Rating } from 'react-simple-star-rating';
import { ICrmLead } from 'services/CrmApi/types/DirectoryEntry';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateLeadMutation } from 'services/CrmApi/mutations/useUpdateLeadMutation';
import { useDefaultOnErrorHandler } from 'hooks/useDefaultOnErrorHandler';

interface IAddLeadProps {
  directoryEntryId: string;
  lead: ICrmLead;
  canEdit: boolean;
  onCancel: () => void;
  onRemove: () => void;
}

export const AddLead: React.FC<IAddLeadProps> = ({ directoryEntryId, lead, onCancel, canEdit, onRemove }) => {
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const { showSuccessNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();

  const createLeadMutation = useCreateLeadMutation();
  const updateLeadMutation = useUpdateLeadMutation();
  const [isSaving, setSaving] = useState(false);

  const crmTypeValuesStages = useCrmTypeValues(ETypeValueType.LEAD_STAGE, { enabled: true });
  crmTypeValuesStages.data?.data?.sort((a, b) => a.order - b.order);
  const stagesTypeOptions: ISingleSelectOption[] = crmTypeValuesStages.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value
    })
  ) ?? [];

  const crmTypeValuesChannels = useCrmTypeValues(ETypeValueType.CHANNEL, { enabled: true });
  const channelsTypeOptions: ISingleSelectOption[] = crmTypeValuesChannels.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value
    })
  ) ?? [];

  const crmTypeValuesSources = useCrmTypeValues(ETypeValueType.SOURCE, { enabled: true });
  const sourceTypeOptions: ISingleSelectOption[] = crmTypeValuesSources.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value
    })
  ) ?? [];

  const crmCountries = useCrmCountries();
  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.name
    })
  ) ?? [];

  const createLead = useCallback(async (values: IAddLeadFormValues) => {
    const requestData : ICreateLeadRequest = {
      directoryEntryId,
      leadStageTypeCode: values.leadStageTypeCode,
      channelTypeCode: values.channelTypeCode ?? null,
      sourceTypeCode: values.sourceTypeCode ?? null,
      scoring: values.scoring ?? null,
      countryScore: values.countryScore.map(item => ({
        annualNumberBookings: Number(item.annualNumberBookings),
        annualValueBookings: Number(item.annualValueBookings),
        countryCode: item.countryCode ?? null,
      })),
    }

    await createLeadMutation.mutateAsync(requestData);
    showSuccessNotification('Lead successfully saved.');
  }, [createLeadMutation, directoryEntryId, showSuccessNotification]);
  
  const updateLead = useCallback(async (leadId: string, values: IAddLeadFormValues) => {
    const requestData : IUpdateLeadRequest = {
      leadId,
      leadStageTypeCode: values.leadStageTypeCode,
      channelTypeCode: values.channelTypeCode ?? null,
      sourceTypeCode: values.sourceTypeCode ?? null,
      scoring: values.scoring ?? null,
      countryScore: values.countryScore.map(item => ({
        annualNumberBookings: Number(item.annualNumberBookings),
        annualValueBookings: Number(item.annualValueBookings),
        countryCode: item.countryCode ?? null,
      })),
    }

    await updateLeadMutation.mutateAsync(requestData);
    showSuccessNotification('Lead successfully updated.');
  }, [showSuccessNotification, updateLeadMutation]);

  const handleFormSubmit = useCallback(async (values: IAddLeadFormValues, formikHelpers: FormikHelpers<IAddLeadFormValues>) => {
    try {
      setSaving(true);
      if (!lead) {
        await createLead(values);
      } else {
        await updateLead(lead.id, values);
      }
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to save a lead');
    } finally {
      setSaving(false);
    }
  }, [createLead, defaultOnErrorHandler, directoryEntryId, lead, queryClient, updateLead]);

  const isLoading = crmTypeValuesStages.isPending || crmTypeValuesChannels.isPending
    || crmTypeValuesSources.isPending || crmCountries.isPending;

  if (isLoading) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (isSaving) {
    return (
      <div className="mt-[20px]">
        <LoadingBar label="Saving..." />
      </div>
    )
  }

  const firstStage = crmTypeValuesStages.data?.data?.find(stage => stage.order === 1)
  const initialValues: IAddLeadFormValues = {
    leadStageTypeCode: lead?.leadStageType.code ?? firstStage?.code,
    channelTypeCode: lead?.channelType?.code ?? null,
    sourceTypeCode: lead?.sourceType?.code ?? null,
    scoring: lead?.scoring,
    countryScore: lead?.countryScore.map(item => ({
      countryCode: item.country.code,
      annualNumberBookings: item.annualNumberBookings,
      annualValueBookings: item.annualValueBookings,
    })) ?? [createEmptyScoreLine()],
  };

  const stageChannelSourceClassname = classnames('stage-channel-source grid gap-[20px] px-[15px]', {
    'grid-cols-2': isMobile,
    'grid-cols-3': isTablet || isDesktop || isDesktopHD,
  });
  const scoringRatingClassname = classnames('scoring-rating-container flex items-center justify-between', {
    'flex-col': isMobile,
    'pr-[40px]': isTablet || isDesktop || isDesktopHD,
  })

  return (
    <div className="add-lead-section">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validateAddLeadForm}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<IAddLeadFormValues>) => {
          return (
            <div className="flex flex-col gap-[20px]">
              <BorderWithLabel label="Info">
                <div className={stageChannelSourceClassname}>
                  <Field name="leadStageTypeCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="leadStageTypeCode"
                        label="Stage *"
                        className="leadStageTypeCode"
                        value={value ?? null}
                        maxVisibleItems={isMobile ? 4 : 6}
                        showSelectedOption
                        scrollToSelectedItem
                        options={stagesTypeOptions ?? []}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={!canEdit || isLoading || isSaving}
                        errorMessage={form.touched.leadStageTypeCode ? form.errors.leadStageTypeCode : null}
                      />
                    )}
                  </Field>
                  <Field name="channelTypeCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="channelTypeCode"
                        label="Channel"
                        className="channelTypeCode"
                        value={value ?? null}
                        maxVisibleItems={isMobile ? 4 : 6}
                        labelWhenNothingSelected='None'
                        showSelectedOption
                        scrollToSelectedItem
                        options={channelsTypeOptions ?? []}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={!canEdit || isLoading || isSaving}
                      />
                    )}
                  </Field>
                  <Field name="sourceTypeCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="sourceTypeCode"
                        label="Source"
                        className={classnames('sourceTypeCode', { 'col-span-2': isMobile })}
                        value={value ?? null}
                        maxVisibleItems={isMobile ? 4 : 6}
                        labelWhenNothingSelected='None'
                        showSelectedOption
                        scrollToSelectedItem
                        options={sourceTypeOptions ?? []}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={!canEdit || isLoading || isSaving}
                      />
                    )}
                  </Field>
                </div>
              </BorderWithLabel>
              <BorderWithLabel label="scoring">
                <div className={scoringRatingClassname}>
                  <div className="scoring-container w-full flex-1">
                    <FieldArray
                      name="countryScore"
                      render={(arrayHelpers: FieldArrayRenderProps) => (
                        <div className="flex flex-col gap-[10px]">
                          <div className="px-[15px] flex items-end">
                            <label
                              className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px] flex-[12] min-w-[90px]')}
                            >
                              Destination
                            </label>
                            <label
                              className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px] min-w-[70px] flex-[8]', { 'ml-[20px]': isTablet || isDesktop || isDesktopHD, 'ml-[10px]': isMobile })}
                            >
                              Annual Value of Bookings (U$S)
                            </label>
                            <label
                              className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px] flex-[5] min-w-[55px]', { 'ml-[20px]': isTablet || isDesktop || isDesktopHD, 'ml-[10px]': isMobile })}
                            >
                              Annual Number of Bookings
                            </label>
                            <span className="min-w-[40px]"></span>
                          </div>
                          {form.values.countryScore.map((countryScore, index) => {
                            const touchedCountryScore = form.touched.countryScore && form.touched.countryScore[index] ? form.touched.countryScore[index]['countryCode'] : null;
                            const errorsCountryScore = form.errors.countryScore && form.errors.countryScore[index] ? form.errors.countryScore[index]['countryCode'] : null;

                            return (
                              <div key={countryScore.id} className="country-score-item px-[15px] flex items-start">
                                <Field name={`countryScore.${index}.countryCode`}>
                                  {({ field: { name, value }, form: { setFieldValue } }) => (
                                    <SingleSelect
                                      fieldId="countryCode"
                                      className="flex-[12] min-w-[90px] countryCode"
                                      value={value ?? null}
                                      maxVisibleItems={isMobile ? 4 : 6}
                                      showSelectedOption
                                      scrollToSelectedItem
                                      options={countryOptions ?? []}
                                      onChange={value => {
                                        setFieldValue(name, value);
                                      }}
                                      disabled={!canEdit || isLoading || isSaving}
                                      errorMessage={touchedCountryScore ? errorsCountryScore : null}
                                    />
                                  )}
                                </Field>
                                <Field name={`countryScore.${index}.annualValueBookings`}>
                                  {({ field: { name, value }, form: { setFieldValue } }) => (
                                    <div className={classnames(`flex flex-col min-w-[70px] flex-[8]`, { 'ml-[20px]': isTablet || isDesktop || isDesktopHD, 'ml-[10px]': isMobile })}>
                                      <DecimalInput
                                        value={value}
                                        className={classnames('annual-value-bookings border border-solid border-gray-40 bg-ivory min-h-[35px] mt-0')}
                                        decimalPlaces={2}
                                        onBlur={value => {
                                          setFieldValue(name, value || 0);
                                        }}
                                        disabled={!canEdit || isLoading || isSaving}
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Field name={`countryScore.${index}.annualNumberBookings`}>
                                  {({ field: { name, value }, form: { setFieldValue } }) => (
                                    <NumberInput
                                      id="annual-number-bookings"
                                      className={classnames('employee-count flex-[5] min-w-[55px] mt-0', { 'ml-[20px]': isTablet || isDesktop || isDesktopHD, 'ml-[10px]': isMobile })}
                                      value={value}
                                      onChange={value => {
                                        setFieldValue(name, value || 0);
                                      }}
                                      disabled={!canEdit || isLoading || isSaving}
                                    />
                                  )}
                                </Field>
                                  <div className="relative ml-[10px] top-[3px]" onClick={() => arrayHelpers.remove(index)}>
                                    <CircleIconButton
                                      className="delete-button"
                                      type="secondary"
                                      iconClass="fas fa-trash-alt"
                                      onClick={() => {}}
                                      disabled={!canEdit || isLoading || isSaving}
                                    />
                                  </div>
                              </div>
                            )
                          })}
                          <div className="add-lead-button-container px-[15px]">
                            <FluidButton
                              type="secondary"
                              className="add-lead-button"
                              fixedWidth="52px"
                              onClick={() => arrayHelpers.push(createEmptyScoreLine())}
                              disabled={!canEdit || isLoading || isSaving}
                            >
                              Add
                            </FluidButton>
                            </div>
                        </div>
                      )}
                    />
                  </div>

                  {/* stars */}
                  <div className="score-container flex flex-col items-center mt-[20px]">
                    <span className="font-pt-sans text-[12px] text-black tracking-[0.48px] uppercase">TOTAL SCORE</span>
                    {form.values.scoring && <span className="score font-pt-sans text-[32px] text-black">{form.values.scoring}</span>}
                    {!form.values.scoring && <span className="score font-pt-sans italic my-[10px] text-[15px] text-gray-100">Not Set</span>}
                    <Field name="scoring">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <Rating
                          size={27}
                          initialValue={value ?? undefined}
                          transition
                          onClick={(value) => {
                            setFieldValue(name, value);
                          }}
                          readonly={!canEdit || isLoading || isSaving}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </BorderWithLabel>

              {/* buttons */}
              <div className="buttons-container flex gap-[10px]">
                {!lead && (
                  <FluidButton
                    type="secondary"
                    className="cancel-lead-button"
                    fixedWidth="78px"
                    onClick={onCancel}
                    disabled={!canEdit}
                  >
                    Cancel
                  </FluidButton>
                )}
                {lead && (
                  <FluidButton
                    type="secondary"
                    className="remove-lead-button"
                    fixedWidth="78px"
                    onClick={onRemove}
                    disabled={!canEdit}
                  >
                    Remove
                  </FluidButton>
                )}
                <FluidButton
                  type="primary"
                  className="add-lead-button"
                  fixedWidth="58px"
                  submit
                  onClick={form.submitForm}
                  isLoading={isSaving}
                  disabled={!canEdit}
                >
                  Save
                </FluidButton>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  )
}