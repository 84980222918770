import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import * as Actions from 'store/modules/travelAgentList/actions';
import * as Selectors from 'store/modules/travelAgentList/selectors';

import FluidButton from 'ui/FluidButton';
import { Link } from 'ui/Link';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { isAdmin as isAdminSelector, isSr } from 'store/modules/auth';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export interface ControlsProps {
  className?: string;
  listLength: number;
}

export const Controls: React.FC<ControlsProps> = props => {
  const request = useSelector(Selectors.requestSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSR = useSelector(isSr);

  const { dynamicParameters } = useDynamicParameters();
  const shouldShowNewTravelAgentCTA = !dynamicParameters.ENABLE_CRM_WORKSPACE_TEMP && (isAdmin || isSR);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleCsvExport = useCallback(() => {
    dispatch(Actions.csvRequestAction());
  }, [dispatch]);

  const handleAdd = useCallback(() => {
    history.push('/travel-agents/create');
  }, [history]);

  const openMassAssignModal = useCallback(() => {
    dispatch(Actions.setMassAssignModalVisibleAction(true));
    dispatch(Actions.initMassAssignAction());
  }, [dispatch]);

  return (
    <div className={classNames(props.className, 'controls w-full flex justify-between items-center')}>
      <div className={classNames(props.className, 'left-controls flex')}>
        {isAdmin && (
          <FluidButton
            disabled={props.listLength <= 0}
            className="mass-assign"
            type="secondary"
            onClick={openMassAssignModal}
          >
            Assign SR to Results
          </FluidButton>
        )}
      </div>
      <div className={classNames(props.className, 'right-controls flex gap-10px')}>
        <FluidButton
          className="export-csv"
          type="secondary"
          onClick={handleCsvExport}
          isLoading={request.csv === ENetworkRequestStatus.PENDING}
        >
          Export CSV
        </FluidButton>
        <Link to="ta-history">
          <FluidButton type={isAdmin ? 'secondary' : 'primary'} className="ta-history">
            TA History
          </FluidButton>
        </Link>
        {shouldShowNewTravelAgentCTA && (
          <FluidButton type="primary" className="create-new" onClick={handleAdd}>
            + New Travel Agent
          </FluidButton>
        )}
      </div>
    </div>
  );
};
