import React, { useState, useEffect, CSSProperties, useCallback, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { IInventoryOptionBespoke, IValueLabelPair } from '../../interfaces';

export interface IMenuDropdownProps {
  fixedIconHtml?: JSX.Element | null;
  fixedHeader: string | null; // If fixedHeader use it, otherwise put the selected option
  options: IInventoryOptionBespoke[] | IValueLabelPair[];
  preSelectedOption: IInventoryOptionBespoke | null;
  onUpdate: (value: IInventoryOptionBespoke) => void;
  isSelected?: boolean;
  hoverMode?: boolean;
  className?: string;
  classNameLabel?: string;
  selectedLabelClassName?: string;
  openClassName?: string;
  itemsClassname?: string; // the "wrapper" class for all the items
  itemCtaClassName?: string; // the class on each CTA itself
  itemCtaPaddingClassName?: string; // a class to apply paddings and spacings, has a default
  itemContentClassName?: string; // the class on each span inside each item
  selectedItemContentClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  isCloseOnSelect?: boolean;
  itemsToDisplayBeforeScroll?: number;
  fontClass?: string; // a class name like "font-hurmegeometric-sans" that sets all fonts
  height?: number; // the height of the element, defaults to 35px
  singleItemHeightPixels?: number; // used for calculating the height of the open box (in conjunction with `itemsToDisplayBeforeScroll`). adjust if you need crazy margins or paddings or text sizes on the items
  isHidden?: boolean;
}

// ComposedPath alternative for Edge
type GetElemAndParentsFn = (elem: any, parents?: any[]) => any[];
const getElemAndParents: GetElemAndParentsFn = (elem, parents = []) => {
  const parent = elem.parentNode;

  if (parent !== null) {
    return getElemAndParents(parent, [...parents, parent]);
  }

  return parents;
};

export const MenuDropdown = (props: IMenuDropdownProps) => {
  const {
    preSelectedOption,
    height = 35,
    fontClass = 'font-pt-sans',
    itemCtaPaddingClassName = 'p-2',
    singleItemHeightPixels = 37,
    fixedHeader,
    fixedIconHtml,
  } = props;
  let { options = [] } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [id] = useState<string>(uuid.v4());

  const [selectedOption, setSelectedOption] = useState(preSelectedOption);

  const handleDocumentMouseDown = useCallback(event => {
    // if the event composed path contains the main menudropdown, dont close it
    // otherwise, do close it
    const elemAndParents =
      typeof event.composedPath === 'function' ? event.composedPath() : getElemAndParents(event.target); // workaround for edge version that do not support composedPath OWA-3124

    const containsSelect = elemAndParents.some(domElement => {
      if (!domElement.classList) {
        return false;
      }
      return domElement.classList.contains(`menudropdown-${id}`);
    });

    // if we've clicked outside
    if (!containsSelect) {
      setIsOpen(false);
    }
  }, []);

  // on mount
  // wire up the event handlers
  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentMouseDown, true);
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Upper component is controling slection through 'preSelectedOption'.
  // It might result from a local selection, homepage selection or URL bar editing.
  useLayoutEffect(() => {
    setSelectedOption(preSelectedOption);
  });

  const handleOptionClick = (option: IInventoryOptionBespoke) => {
    setSelectedOption(option);
    if (props.isCloseOnSelect) {
      setIsOpen(false);
    }
    props.onUpdate(option);
  };

  const handleMenudropdownClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (!props.disabled) {
        setIsOpen(!isOpen);
      }
    },
    [props.disabled, isOpen, setIsOpen]
  );

  const ref = useRef<HTMLDivElement>(null);

  // Using one single callback and checking 'event.keyCode' is tricky as browsers may manage this var differently.
  const handleMouseOver = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const node = ref.current;
    if (node && props.hoverMode) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  const { itemsToDisplayBeforeScroll = 10 } = props;
  let additionalStyleBlock: CSSProperties = {};

  if (options.length > itemsToDisplayBeforeScroll) {
    additionalStyleBlock = {
      overflow: 'hidden',
      overflowY: 'scroll',
      maxHeight: `${singleItemHeightPixels * itemsToDisplayBeforeScroll}px`,
    };
  }

  return (
    <div
      ref={ref}
      tabIndex={0}
      className={classNames(
        `menudropdown-wrapper ${props.className ? props.className : ''} ${
          isOpen ? props.openClassName : ''
        } menudropdown-${id} relative ${fontClass} ${props.isHidden ? 'hidden' : ''}`
      )}
    >
      <div
        onClick={props.hoverMode ? () => {} : handleMenudropdownClick}
        className={`${selectedOption?.value?.toLowerCase()} flex relative cursor-pointer items-center ${
          isOpen ? 'is-open' : ''
        }`}
      >
        {fixedIconHtml ? fixedIconHtml : selectedOption ? selectedOption.iconHtmlBig : null}
        <span
          className={classNames(
            `menudropdown-label flex text-black default:items-center default:cursor-pointer ${props.classNameLabel}`,
            `${props.isSelected ? props.selectedLabelClassName : ''}`
          )}
        >
          {fixedHeader ? fixedHeader : selectedOption ? selectedOption.label : ''}
        </span>
        <div
          style={{
            minHeight: height + 'px',
            maxHeight: height + 'px',
          }}
          className={`flex justify-center items-center top-0 right-0 min-w-35px max-w-35px cursor-pointer`}
        >
          <i
            className={classNames('fas relative text-black transition-rotate duration-500 ease-in-out fa-caret-up', {
              '-rotate-180': !isOpen,
            })}
          ></i>
        </div>
      </div>

      {isOpen && (
        <div
          className={`menudropdown-items space-y-5px absolute min-w-full w-[max-content] z-50 shadow-inv-shadow-md ${props.itemsClassname ||
            ''} ${isOpen ? 'is-open' : ''}`}
          style={additionalStyleBlock}
        >
          {options.length <= 0 && (
            <div className="flex items-center min-h-11 outline-none">
              <span className="p-3 text-gray-80 italic">No options available</span>
            </div>
          )}
          {options.map((option, optionIndex) => {
            if (option.isDivider) {
              return (
                <div key={`divider-${optionIndex}`}>
                  <hr
                    style={{
                      borderTop: '1px solid #D5D2D1', // styling HRs with classes is weird, unfortunately
                    }}
                    className="m-0"
                  />
                </div>
              );
            }

            const selectedItemContentClassName = props.selectedItemContentClassName || 'text-teal-100 font-bold';

            return (
              <div
                key={`${option.value}-${optionIndex}`}
                style={{
                  minHeight: height + 'px',
                }}
                className={classNames(
                  `${option.value?.toLowerCase()} menudropdown-item-wrapper index-${optionIndex} flex flex-col items-center outline-none`,
                  {
                    selected: selectedOption ? selectedOption.value === option.value : false,
                    hidden: (option.isHidden && option.isHidden()) || false,
                  }
                )}
              >
                <label
                  className={`menudropdown-item-cta cursor-pointer flex flex-1 items-center w-full ${itemCtaPaddingClassName} ${props.itemCtaClassName ||
                    ''}`}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOptionClick(option);
                  }}
                >
                  <button
                    className={classNames(
                      `flex flex-row items-center p-0 bg-transparent cursor-pointer border-none outline-none text-sm text-left`,
                      {
                        [selectedItemContentClassName]: selectedOption ? selectedOption.value === option.value : false,
                        'w-full justify-between': option.iconAlign === 'right',
                      },
                      props.itemContentClassName
                    )}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleOptionClick(option);
                    }}
                  >
                    {option.iconAlign && option.iconAlign === 'right' && (
                      <React.Fragment>
                        <span className="item-label text-black text-15px pl-3 pr-3">{option.label}</span>
                        {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                        {option.iconHtml && option.iconHtml}
                      </React.Fragment>
                    )}

                    {option.iconAlign && option.iconAlign === 'left' && (
                      <React.Fragment>
                        {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                        {option.iconHtml && option.iconHtml}
                        <span className="item-label text-black text-15px pl-3 pr-3">{option.label}</span>
                      </React.Fragment>
                    )}

                    {option.iconAlign == null && (
                      <React.Fragment>
                        {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                        {option.iconHtml && option.iconHtml}
                        <span className="item-label text-black text-15px pl-3 pr-3">{option.label}</span>
                      </React.Fragment>
                    )}
                  </button>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
