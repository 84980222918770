import { Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { ContactProfileInfo } from './ContactProfileInfo';
import { IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';
import { contactProfileFormValidationSchema, IContactProfileFormValues } from './contactProfileForm';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { useUpdateContactMutation } from 'services/CrmApi/mutations/useUpdateContactMutation';
import { IUpdateContactRequest } from 'services/CrmApi/types/CrmContactTypes';
import { useNotifications } from 'hooks/useNotifications';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useDefaultOnErrorHandler } from 'hooks/useDefaultOnErrorHandler';

interface ICrmContactEditProfileParams {
  directoryEntry: IDirectoryEntry;
}

export const CrmContactEditProfile: React.FC<ICrmContactEditProfileParams> = ({ directoryEntry }) => {
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const profile = directoryEntry.profile;

  const queryClient = useQueryClient();
  const updateContactMutation = useUpdateContactMutation();

  const handleFormSubmit = useCallback(async (values: IContactProfileFormValues, formikHelpers: FormikHelpers<IContactProfileFormValues>) => {
    const requestData : IUpdateContactRequest = {
      directoryId: directoryEntry.id,
      profile: {
        contactTypeCode: values.contactTypeCode,
        firstName: values.firstName,
        lastName: values.lastName ?? null,
        titleTypeCode: values.titleTypeCode ?? null,
        landline: values.landline ?? null,
        region: values.region ?? null,
        birthday: values.birthday ?? null,
        personalDocumentTypeCode: values.personalDocumentTypeCode ?? null,
        personalDocumentNumber: values.personalDocumentNumber ?? null,
        personalDocumentCountryCode: values.personalDocumentCountryCode,
        roleTypeCode: values.roleTypeCode,
        roleStart: values.roleStart,
        roleEnd: values.roleEnd,
      },
      email: !isNil(values.email) && values.email.length > 0 ? values.email : null,
      countryCode: values.countryCode ?? null,
      acceptsEmail: values.acceptsEmail,
      mobile: values.mobile ?? null,
      address: values.address ?? null,
      linkedin: values.linkedin ?? null,
      twitter: values.twitter ?? null,
      instagram: values.instagram ?? null,
    }

    try {
      await updateContactMutation.mutateAsync(requestData);
      showSuccessNotification('Contact updated.');
      queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntry.id] })
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to update a contact');
      queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntry.id] })
    }
  }, [defaultOnErrorHandler, directoryEntry.id, queryClient, showSuccessNotification, updateContactMutation]);

  if (!('contactType' in profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a contact profile" />
      </div>
    )
  }

  const initialValues: IContactProfileFormValues = {
      contactTypeCode: profile.contactType?.code ?? '',
      firstName: profile?.firstName,
      lastName: profile?.lastName ?? '',
      titleTypeCode: profile?.titleType?.code ?? null,
      landline: profile?.landline ?? '',
      region: profile?.region ?? '',
      birthday: profile?.birthday,
      personalDocumentTypeCode: profile?.personalDocumentType?.code ?? null,
      personalDocumentNumber: profile?.personalDocumentNumber ?? '',
      personalDocumentCountryCode: profile?.personalDocumentCountry?.code ?? null,
      roleTypeCode: profile?.role?.code ?? null,
      roleStart: profile?.roleStart,
      roleEnd: profile?.roleEnd,
      email: directoryEntry.email ?? '',
      countryCode: directoryEntry.country?.code ?? null,
      acceptsEmail: directoryEntry.acceptsEmail,
      mobile: directoryEntry.mobile ?? '',
      address: directoryEntry.address ?? '',
      linkedin: directoryEntry.linkedin ?? '',
      twitter: directoryEntry.twitter ?? '',
      instagram: directoryEntry.instagram ?? '',
  };
  
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={contactProfileFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      <ContactProfileInfo isSaving={updateContactMutation.isPending || queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntry.id] }) > 0} />
    </Formik>
  )
};
