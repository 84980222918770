import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash-es';

import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Selectors from 'store/modules/companyList/selectors';
import * as Actions from 'store/modules/companyList/actions';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { Pagination } from 'pureUi/Pagination';
import { TravelCompaniesTable } from './TravelCompaniesTable';
import { Filter } from './Filter';
import { BackLink } from 'ui/BackLink';
import { useHistory } from 'react-router';
import { PageTitle } from 'ui/PageTitle';

export const TravelCompanyList: React.FC = () => {
  const list = useSelector(Selectors.listSelector);
  const request = useSelector(Selectors.requestSelector);

  const filter = useSelector(Selectors.filterSelector);
  const pagination = useSelector(Selectors.paginationSelector);

  const isInitialized = !isNil(pagination.total);
  const isLoading = request.get === ENetworkRequestStatus.PENDING;
  const isError = request.get === ENetworkRequestStatus.ERROR;

  const pageCount = useMemo(
    () => (!isNil(pagination.total) ? Math.ceil(pagination.total / pagination.perPage) : null),
    [pagination.total, pagination.perPage]
  );

  const { dynamicParameters } = useDynamicParameters();
  const shouldShowNewCompanyCTA = !dynamicParameters.ENABLE_CRM_WORKSPACE_TEMP;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(Actions.getRequestAction());
  }, [dispatch]);

  const handleSearch = useCallback(v => dispatch(Actions.setSearchFilterAction(v)), [dispatch]);

  const handleCountryCodeChange = useCallback(v => dispatch(Actions.setCountryCodeFilterAction(v)), [dispatch]);

  const handlePageSelect = useCallback(page => dispatch(Actions.setPageAction(page - 1)), [dispatch]);

  const handlePerPageChange = useCallback(perPage => dispatch(Actions.setPerPageAction(perPage)), [dispatch]);

  const handleCreateCompany = useCallback(() => {
    history.push('/travel-companies/create');
  }, [history]);

  const handleDelete = useCallback(() => {}, []); //TODO: handle delete

  const handleCsvExport = useCallback(() => {
    dispatch(Actions.travelCompanyCsvRequestAction());
  }, [dispatch]);

  return (
    <div className="company-list w-1280px mx-auto">
      <BackLink type="back">Back</BackLink>
      <PageTitle
        primaryText="Travel Companies"
        secondaryCondition={isInitialized && !isLoading}
        secondaryText={`(${pagination.total || 'no'} results found)`}
        loadingCondition={isLoading}
        loadingText="Loading ..."
      />
      {isError && <ErrorBar />}
      {!isError && !isInitialized && <LoadingBar />}
      {!isError && isInitialized && (
        <>
          <div className="controls flex justify-between items-end mt-5">
            <Filter value={filter} onSearch={handleSearch} onCountryCodeChange={handleCountryCodeChange} />
            <div className="flex gap-10px">
              <FluidButton
                type="secondary"
                onClick={handleCsvExport}
                isLoading={request.csv === ENetworkRequestStatus.PENDING}
              >
                Export CSV
              </FluidButton>
              {shouldShowNewCompanyCTA && (
                <FluidButton type="primary" onClick={handleCreateCompany}>
                  + New Company
                </FluidButton>
              )}
            </div>
          </div>
          <div className="mt-5">
            <TravelCompaniesTable
              className="mt-20px"
              companies={list || []}
              onDelete={handleDelete}
              loading={isLoading}
            />
          </div>
          {(list || []).length >= 1 && (
            <Pagination
              className="pagination mt-40px"
              onPageSelect={handlePageSelect}
              pageCount={pageCount || 0}
              currentPage={pagination.page + 1}
              itemsPerPage={pagination.perPage}
              onItemsPerPageChange={handlePerPageChange}
            />
          )}
        </>
      )}
    </div>
  );
};
