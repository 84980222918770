import React, { useCallback } from 'react';
import { BackLink } from 'ui/BackLink';
import classnames from 'classnames';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { VerticalSpace } from 'ui/VerticalSpace';
import { useRouteMatch } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';
import { ECrmDirectoryEntryType } from 'services/CrmApi/types/DirectoryEntry';
import { CrmCompanyTabs } from './CrmCompanyTabs/CrmCompanyTabs';
import { CompanyPageTitle } from '../components/CompanyPageTitle';
import { CrmCompanyEditProfile } from './CrmCompanyProfile/CrmCompanyEditProfile';
import { CrmPublishDirectoryEntry } from '../components/CrmPublishDirectoryEntry/CrmPublishDirectoryEntry';
import { useSelector } from 'react-redux';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { isCompanyProfile } from '../helpers';

interface ICrmCompanyEditRouteParams {
  companyId: string;
}

export const CrmCompanyEdit = () => {
  const match = useRouteMatch<ICrmCompanyEditRouteParams>();
  const companyId = match.params.companyId;
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();

  const isSales = useSelector(AuthSelectors.isSales);
  const isFinance = useSelector(AuthSelectors.isFinanceUser);
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  
  const crmCountries = useCrmCountries();
  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const { crmDirectoryEntryResponse, directoryEntry } = useCrmDirectoryEntry(companyId);

  const contacts = [... (directoryEntry?.linkedDirectoryEntries || []) ].filter( directoryEntry => directoryEntry.type === ECrmDirectoryEntryType.PERSON );
  
  const messages: string[] = [];
  if (!directoryEntry?.country) {
    messages.push('Country is required.');
  }
  if (!directoryEntry?.address) {
    messages.push('Address is required.');
  }
  if (!contacts.filter(item => item.contactType?.code === 'TA')?.length) {
    messages.push('Travel Agent contact is required.');
  }
  const isPublished = !!directoryEntry?.publishedBy && !!directoryEntry?.publishedDate;
  const isTravelCompany = directoryEntry?.profile.type === ECrmDirectoryEntryType.COMPANY
    && isCompanyProfile(directoryEntry?.profile)
    && directoryEntry?.profile.companyType.code === 'TC'; // TC is the code for Travel company

  const layoutClassname = classnames('flex gap-[20px]', {
    'flex-col': isMobile || isTablet,
    'flex-row': isDesktop || isDesktopHD,
  });

  const onLinkOrUnlink = useCallback( () => {
    crmDirectoryEntryResponse.refetch();
  }, [crmDirectoryEntryResponse]);

  if (crmCountries.isPending || crmTypeValuesCompanies.isPending || crmDirectoryEntryResponse.isPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesCompanies.isError || !directoryEntry || !directoryEntry.profile) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }
 
  if (directoryEntry.profile.type !== ECrmDirectoryEntryType.COMPANY || !('companyType' in directoryEntry.profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a company profile" />
      </div>
    )
  }

  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <CompanyPageTitle
        primaryText={directoryEntry?.fullName ?? ''}
        countryCode={directoryEntry?.country?.code}
        directoryEntry={directoryEntry}
        companyType={directoryEntry?.profile.companyType.value}
      />
      <VerticalSpace height="20px" />
      <div className={layoutClassname}>
        <CrmCompanyEditProfile isPublished={isPublished} directoryEntry={directoryEntry} />
        <div className="flex-1 flex flex-col gap-[20px]">
          {!isPublished && (isAdmin || isFinance || isSales) && isTravelCompany && (
            <CrmPublishDirectoryEntry
              messages={messages}
              directoryEntryId={directoryEntry.id}
            />
          )}
          <CrmCompanyTabs directoryEntry={directoryEntry} contacts={contacts} onLinkOrUnlink={onLinkOrUnlink} />
        </div>
      </div>
    </div>
  )
};
