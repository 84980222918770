import React from 'react';

export interface iItineraryHeaderProps {
  backgroundImage: string;
  title: string;
  subtitles?: string[];
}

export const ItineraryHero = (props: iItineraryHeaderProps) => {
  return (
    <div className="relative w-full h-[500px] overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center w-full h-full"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          filter: 'brightness(0.85)',
        }}
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-30" />

      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
        <h1 className="font-noe-display text-5xl md:text-6xl lg:text-7xl uppercase tracking-wider mb-4 md:mb-6">
          {props.title}
        </h1>

        <div className="text-lg md:text-xl space-y-1">
          {props.subtitles?.map((subtitle, index) => (
            <span className="font-noe-display block" key={index}>
              {subtitle}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
