import classNames from 'classnames';
import React from 'react';
import FluidButton from 'ui/FluidButton';
import { InvHeaderLogo } from 'ui/InvHeader/InvHeaderLogo';
import { MenuDropdown } from 'ui/MenuDropdown';
import { MenuSingleElement } from 'ui/MenuSingleElement';

export const ItineraryHeader = () => {
  return (
    <div className="inv-header sticky w-full my-0 top-0 left-0 right-0 bg-white z-10 px-[20px] flex items-center justify-between min-h-80px max-h-80px">
      <InvHeaderLogo />

      <div className="actions flex items-center justify-end gap-2">
        {/* <span>Trip Overview</span> */}
        <MenuSingleElement
          onUpdate={() => {}}
          className={`h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans text-15px cursor-pointer hover:bg-gray-10 rounded-3px`}
          selectedClassName="font-bold o:text-teal"
          option={{
            label: 'Trip Overview',
            value: 'trip-overview',
          }}
        />
        <MenuSingleElement
          onUpdate={() => {}}
          className={`h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans text-15px cursor-pointer hover:bg-gray-10 rounded-3px`}
          selectedClassName="font-bold o:text-teal"
          option={{
            label: 'Map',
            value: 'map',
          }}
        />
        <MenuDropdown
          className="h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans cursor-pointer rounded-3px hover:bg-gray-10"
          classNameLabel="pl-1 pr-1 text-15px"
          selectedLabelClassName="font-bold o:text-teal"
          itemCtaPaddingClassName="p-0"
          itemsClassname="bg-white p-2 rounded-3px"
          itemCtaClassName="hover:bg-gray-10 rounded-3px"
          onUpdate={() => {
            console.log('clicked');
          }}
          isCloseOnSelect={true}
          options={[
            {
              label: 'Day 1',
              value: '1',
            },
            {
              label: 'Day 2-9',
              value: '2',
            },
            {
              label: 'Day 10',
              value: '3',
            },
          ]}
          preSelectedOption={null}
          fixedHeader={'Itinerary'}
          fixedIconHtml={null}
          hoverMode={false}
        />
        {/* <span>Itinerary</span> */}
        <MenuSingleElement
          onUpdate={() => {}}
          className={`h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans text-15px cursor-pointer hover:bg-gray-10 rounded-3px`}
          selectedClassName="font-bold o:text-teal"
          option={{
            label: 'Prices',
            value: 'prices',
          }}
        />
        {/* <span>Prices</span> */}
        <FluidButton
          type="primary"
          onClick={() => {
            console.log('clicked');
          }}
        >
          Edit Itinerary
        </FluidButton>
      </div>
    </div>
  );
};
