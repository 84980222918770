import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";

export const usePublishDirectoryEntryMutation = () => {
  const crmApi = makeCRMApi();
  
  const publishDirectoryEntryMutation = useMutation({
    mutationKey: ['crm-publish-directory-entry'],
    mutationFn: (directoryId: string) => crmApi.publishDirectoryEntry(directoryId),
  });
  
  return publishDirectoryEntryMutation;
}
