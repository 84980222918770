import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import * as AuthSelectors from 'store/modules/auth/selectors';
import Editor from 'pureUi/Editor';
import React, { useCallback, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import { useSelector } from 'react-redux';
import { useBEUsers } from 'services/BackendApi/queries/useBEUsers';
import { userToOption } from 'ui/SingleSelect/helpers';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { contactMainInfoValidationSchema, ICrmContactMainInfoFormValues } from './crmContactMainInfoForm';
import { useUpdateContactMutation } from 'services/CrmApi/mutations/useUpdateContactMutation';
import { IUpdateContactRequest } from 'services/CrmApi/types/CrmContactTypes';
import { useNotifications } from 'hooks/useNotifications';
import { useQueryClient } from '@tanstack/react-query';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { isDirtyEditContactProfileSelector } from 'store/modules/crm/selectors';
import { VerticalSpace } from 'ui/VerticalSpace';
import { ILinkedDirectoryEntryArrayItem } from 'services/CrmApi/types/DirectoryEntry';
import { CollapsibleHeader } from 'containers/CRM/components/CollapsibleHeader/CollapsibleHeader';
import { LinkedContactsList } from 'containers/CRM/editCompany/LinkedContactsList/LinkedContactsList';
import { LinkedContactsAdd } from 'containers/CRM/editCompany/LinkedContactsAdd/LinkedContactsAdd';
import { LinkedCompaniesAdd } from '../LinkedCompaniesAdd/LinkedCompaniesAdd';
import { LinkedCompaniesList } from '../LinkedCompaniesList/LinkedCompaniesList';
import { ModalV1 } from 'ui/ModalV1';
import { useHistory } from 'react-router';
import { AddLead } from 'containers/CRM/components/AddLead/AddLead';
import { useRemoveLeadMutation } from 'services/CrmApi/mutations/useRemoveLeadMutation';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { useDefaultOnErrorHandler } from 'hooks/useDefaultOnErrorHandler';

interface ICrmContactMainInfoProps {
  directoryEntryId: string;
  contacts: ILinkedDirectoryEntryArrayItem[];
  companies: ILinkedDirectoryEntryArrayItem[];
  onLinkOrUnlink: () => void;
}

export const CrmContactMainInfo: React.FC<ICrmContactMainInfoProps> = ({ directoryEntryId, contacts, companies, onLinkOrUnlink }) => {
  const isDirtyEditContactProfile = useSelector(isDirtyEditContactProfileSelector);
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const history = useHistory();

  const isSr = useSelector(AuthSelectors.isSr);
  const isSales = useSelector(AuthSelectors.isSales);
  const canEdit = !isSr;
  const beUsers = useBEUsers('');
  const managerOptions: ISingleSelectOption[] = beUsers?.data?.data?.data
    ?.map(user => userToOption(user)) ?? [];

  const queryClient = useQueryClient();
  const { crmDirectoryEntryResponse, directoryEntry } = useCrmDirectoryEntry(directoryEntryId);
  const updateContactMutation = useUpdateContactMutation();
  const isLoading = queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntryId] }) > 0;
  const isSaving = updateContactMutation.isPending;
  const { isMobile } = useResponsive();

  const [openRedirectModal, setOpenRedirectModal] = useState<boolean>(false);

  const removeLeadMutation = useRemoveLeadMutation();
  const [isRemovingLead, setIsRemovingLead] = useState(false);
  const [isRemoveLeadModalOpen, setRemoveLeadModalOpen] = useState(false);
  const [isLeadShown, setLeadShown] = useState(!!directoryEntry?.lead);
  const handleShowLead = useCallback(() => {
    setLeadShown(true);
  }, []);
  const handleHideLead = useCallback(() => {
    setLeadShown(false);
  }, []);
  const handleRemoveLead = useCallback(() => {
    setRemoveLeadModalOpen(true);
  }, []);
  const handleCloseRemoveLeadModal = useCallback(() => {
    setRemoveLeadModalOpen(false);
  }, []);
  const handleConfirmRemoveLeadModal = useCallback(async () => {
    if (!directoryEntry?.lead?.id) {
      return;
    }

    try {
      setIsRemovingLead(true);
      await removeLeadMutation.mutateAsync(directoryEntry?.lead?.id);
      showSuccessNotification('Lead successfully deleted.');      
      setLeadShown(false);
      setRemoveLeadModalOpen(false);
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to update a lead.');
    } finally {
      setIsRemovingLead(false);
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
    }
  }, [defaultOnErrorHandler, directoryEntry?.lead?.id, directoryEntryId, queryClient, removeLeadMutation, showSuccessNotification]);

  const initialValues: ICrmContactMainInfoFormValues = {
    managerId: directoryEntry?.manager?.id ?? null,
    keyNotes: directoryEntry?.keyNotes ?? '',
  };

  const handleCloseRedirectModal = useCallback( () => {
    history.push('/crm');
  }, [history]);

  const handleFormSubmit = useCallback(async (values: ICrmContactMainInfoFormValues, formikHelpers: FormikHelpers<ICrmContactMainInfoFormValues>) => {
    const requestData : IUpdateContactRequest = {
      ...values,
      directoryId: directoryEntryId,
    }

    try {
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      await updateContactMutation.mutateAsync(requestData);
      showSuccessNotification('Contact updated.');      
      if(initialValues.managerId !== values.managerId && isSales) {
        setOpenRedirectModal(true);
      }
    } catch (error) {
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      defaultOnErrorHandler(error, 'Failed to update a Contact');
    } finally {
      formikHelpers.resetForm();
    }
  }, [defaultOnErrorHandler, directoryEntryId, initialValues.managerId, isSales, queryClient, showSuccessNotification, updateContactMutation]);

  if (beUsers.isPending || crmDirectoryEntryResponse.isPending) {
    return (
      <div className="w-full flex justify-center mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (!directoryEntry || !('contactType' in directoryEntry.profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a contact profile" />
      </div>
    )
  }

  const titleCompanies = (
    <p className="font-pt-sans text-[17px] text-black m-0">
      <span className="mr-[5px] font-bold">Companies</span>
      <span>({companies?.length ?? 0})</span>
    </p>
  );

  const titleLead = (
    <p className="font-pt-sans text-[17px] text-black m-0">
      <span className="mr-[5px] font-bold">Lead</span>
      {!!directoryEntry?.lead && <span>(1)</span>}
    </p>
  );
  
  const titleContacts = (
    <p className="font-pt-sans text-[17px] text-black m-0">
      <span className="mr-[5px] font-bold">Contacts</span>
      <span>({contacts?.length ?? 0})</span>
    </p>
  );

  return (
    <div className="main-info-container w-full px-[10px] pt-[15px]">
      {openRedirectModal && (
        <ModalV1
          className="redirect-modal min-w-[500px] max-w-[500px]"
          title="You are being redirected"
          message="Data have been updated successfully, but as you changed the Manager you no longer have access to this resource"
          onClose={handleCloseRedirectModal}
        />
      )}
      <h3 className="section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0">
        Main Info
      </h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={contactMainInfoValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<ICrmContactMainInfoFormValues>) => {
          return (
            <>
              <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] ">
                <LeaveWithoutSavingModal
                  when={form.dirty || isDirtyEditContactProfile}
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />
                <Field name="managerId">
                  {({ field: { name, value }, form: { setFieldValue } }) => (
                    <SingleSelect
                      fieldId="manager"
                      label="Manager/Owner *"
                      className="manager"
                      value={value}
                      showSelectedOption
                      maxVisibleItems={isMobile ? 4 : 6}
                      scrollToSelectedItem
                      options={managerOptions ?? []}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                      disabled={!canEdit || isLoading || isSaving}
                      errorMessage={form.touched.managerId && form.errors.managerId ? form.errors.managerId : null}
                      errorClassName="manager-error"
                    />
                  )}
                </Field>
                <div className="key-notes flex  gap-[20px]">
                  <Field name="keyNotes">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <div className="flex flex-col w-full">
                        <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                          Key Notes
                        </label>
                        <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} disabled={!canEdit || isLoading || isSaving} />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="buttons-container ">
                  <FluidButton type="primary" textClassName="flex items-center gap-[10px]" isLoading={isLoading || isSaving} disabled={!canEdit} onClick={form.submitForm}>
                      Save
                  </FluidButton>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
      <VerticalSpace height="40px" />
      <CollapsibleHeader title={titleLead}>
        {!isLeadShown && (
          <FluidButton type="secondary" textClassName="flex items-center gap-[10px]" disabled={!canEdit} onClick={handleShowLead}>
            Add
          </FluidButton>
        )}
        {isLeadShown && (
          <AddLead 
            directoryEntryId={directoryEntryId}
            lead={directoryEntry.lead}
            onCancel={handleHideLead}
            canEdit={canEdit}
            onRemove={handleRemoveLead} 
          />
        )}
      </CollapsibleHeader>
      <VerticalSpace height="40px" />
      <CollapsibleHeader title={titleCompanies}>
        <LinkedCompaniesList 
          companies={companies} 
          onCompanyUnlink={onLinkOrUnlink}
        />
        <LinkedCompaniesAdd
          directoryEntryId={directoryEntryId}
          onCompanyLink={onLinkOrUnlink}
          contactCountryCode={directoryEntry?.country?.code || null}
        />
      </CollapsibleHeader>
      <VerticalSpace height="40px" />
      <CollapsibleHeader title={titleContacts}>
        <LinkedContactsList 
          contacts={contacts}
          onContactUnlink={onLinkOrUnlink}
        />
        <LinkedContactsAdd
          directoryEntryId={directoryEntryId}
          onContactLink={onLinkOrUnlink}
        />
      </CollapsibleHeader>

      {isRemoveLeadModalOpen && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-remove-lead-modal"
          title="You are going to remove a Lead."
          message="Are you sure?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleConfirmRemoveLeadModal}
          onCancel={handleCloseRemoveLeadModal}
          isConfirmLoading={isRemovingLead}
        />
      )}
    </div>
  );
}