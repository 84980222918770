import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IBasketBuildL4, IGetSharedLinkListResponse } from 'services/BackendApi/types/Basket';
import { convertBasketBuildToBookingBuilderFormat } from './utils';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import PencilIcon from 'ui/Icons/pencil-no-circle.component.svg';
import TrashIcon from 'ui/Icons/trash-no-circle.component.svg';
import CopyIcon from 'ui/Icons/copy.component.svg';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import {
  formatDateDisplay,
  formatDateDisplayWithoutOffset,
  formatPrice,
  generateArrayOfDatesBetweenWithoutOffset,
  getCurrencySymbol,
} from 'utils';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BasketItemExpandableInfo } from './BasketItemExpandableInfo';
import { useTranslation } from 'react-i18next';
import ArrowDown from 'ui/Icons/keyboard_arrow_down.component.svg';
import PictureIcon from 'ui/Icons/components/Picture.component';
import { ConfirmationModal, EConfirmationModalType } from '../../ui/ConfirmationModal';
import { makeBackendApi, ENetworkRequestStatus } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';
import RejectedIcon from 'ui/Icons/rejected.component.svg';
import PriorityIcon from 'ui/Icons/priority-high.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { isNil } from 'lodash-es';
import { BookingBuilder } from 'services/BackendApi';
import * as DateFNS from 'date-fns';
import PureCheckbox from 'pureUi/Checkbox';
import { AddTACommissionModal } from '../../ui/Basket/AddTACommissionModal';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
export enum eBasketItemRenderType {
  BASKET_PAGE = 'BASKET_PAGE',
  SHARED_LINK = 'SHARED_LINK',
}
import { theme } from '../../../tailwind.config';
import { BasketHotelPhotoGalleryModal } from './BasketHotelPhotoGalleryModal';
import { RadioButtonSingle } from 'components/RadioButtons/RadioButtons';
import FluidButton from 'ui/FluidButton';
import { OnHoldInfoPrimary } from './OnHoldInfoPrimary';
import { min } from 'date-fns';

interface IBasketItemProps {
  build: IBasketBuildL4;
  isSelected: boolean;
  onSelectBuild: (buildUuid: string, newState: boolean) => void;
  selectionType: 'single' | 'multiple' | 'none';
  onEdit: () => void;
  onDeleteBuild?: (buildUuid: string) => void;
  onUpdateBuildCommission?: (buildUuid: string, taMarginAmount: string) => void;
  actingOnBehalfOfUser?: iActingOnBehalfOfUser;
  selectedTaUserUuid?: string | null;
  basketItemRenderType: eBasketItemRenderType;
  basketItemSharedTo?: IGetSharedLinkListResponse['shareType'];
  onCopy?: () => void;
}

export interface IOnHoldPerRoomInfo {
  isValid: boolean;
  deadline: Date;
  systemBookingId: string;
  productId: string,
}

export interface IOnHoldInfo {
  isOnHold: boolean;
  isSetValid: boolean;
  setDeadline: Date;
  perRoom: IOnHoldPerRoomInfo[];
}

const countString = (itemCount: number, noun: string) => {
  if (itemCount === 0) {
    return `No ${noun}s`;
  } else if (itemCount === 1) {
    return `${itemCount} ${noun}`;
  } else {
    return `${itemCount} ${noun}s`;
  }
};

const RateTypeBadge = ({ rateType }: { rateType: ERoomRateType }) => {
  switch (rateType) {
    case ERoomRateType.STATIC:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px uppercase">
          static rate
        </span>
      );

    case ERoomRateType.LIVE:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-teal-20 text-12px leading-14px uppercase">
          live rate
        </span>
      );
    default:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px">
          static rate
        </span>
      );
  }
};

export const BasketItem = (props: IBasketItemProps) => {
  // we add a day to the end to make it render correctly, and then _minus_ 1 from the total length
  // because we don't want to include the end date in the length (because the length is nights)
  const dispatch = useDispatch();

  const startDate = props.build.buildRequest.startDate;
  const endDate = DateFNS.addDays(new Date(props.build.buildRequest.endDate), 1);
  const lengthOfStay = generateArrayOfDatesBetweenWithoutOffset(startDate, endDate).length - 1;

  const dateRangeString = `${formatDateDisplay(startDate)} - ${formatDateDisplayWithoutOffset(endDate)}`;
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false);

  const accommodationCount = props.build.buildRequest.Accommodation?.length || 0;
  const transferCount = props.build.buildRequest.Transfer?.length || 0;
  const groundServiceCount = props.build.buildRequest['Ground Service']?.length || 0;

  const guestCount =
    props.build.buildRequest.guestAges.numberOfAdults + props.build.buildRequest.guestAges.agesOfAllChildren.length;

  const currencySymbol = getCurrencySymbol(props.build.initialBuildResponse.currency);

  const onHoldInfo : IOnHoldInfo = {
    isOnHold: !isNil(props.build.holdSupplierResponse),
    isSetValid: props.build.holdSupplierResponse?.externalBookings.every( externalBooking => new Date(externalBooking.holdDeadline) > new Date() ) || false,
    setDeadline: min(props.build.holdSupplierResponse?.externalBookings.map( externalBooking => new Date(externalBooking.holdDeadline)) || []),
    perRoom: props.build.holdSupplierResponse?.externalBookings.map( externalBooking => ({
      isValid: new Date(externalBooking.holdDeadline) > new Date(),
      deadline: new Date(externalBooking.holdDeadline || 0),
      systemBookingId: externalBooking.systemBookingId || '',
      productId: externalBooking.accommodations[0].productId,
    })) || [],
  };

  const hasLatestBookingBuild = !isNil(props.build.latestBookingBuilderResponse);

  // define the 2 booking builders (latest and initial)
  const latestBookingBuilder: BookingBuilder | undefined = hasLatestBookingBuild
    ? convertBasketBuildToBookingBuilderFormat(props.build, true)
    : undefined;
  const initialBookingBuilder = convertBasketBuildToBookingBuilderFormat(props.build, false);

  // determine which one we're going to use for rendering everything
  const effectiveBookingBuilder =
    hasLatestBookingBuild && latestBookingBuilder?.response.canBeBooked === true && !onHoldInfo.isSetValid
      ? latestBookingBuilder
      : initialBookingBuilder;

  // a bunch of convenience variables
  const rateType = effectiveBookingBuilder.response?.potentialBooking?.Accommodation[0]?.isLiveRate
    ? ERoomRateType.LIVE
    : ERoomRateType.STATIC;

  const initialTotalCostString = initialBookingBuilder.response.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(initialBookingBuilder.response?.totals.total || 0);
  const latestTotalCostString = latestBookingBuilder?.response?.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(latestBookingBuilder?.response?.totals.total || 0);

  const commissionPercent: number = parseFloat(props.build.taMarginAmount || '0');
  const totalAmount: number = parseFloat(latestBookingBuilder?.response?.totals.total || '0');
  const commissionAmount = totalAmount > 0 ? (commissionPercent / 100) * totalAmount : 0;

  const commissionText =
    commissionAmount > 0
      ? `${commissionPercent.toFixed(2)}% (${currencySymbol}${formatPrice(commissionAmount)})`
      : null;

  const hasCommission = commissionAmount > 0;
  const commissionButtonText = `${!hasCommission ? `ADD COMMISSION` : `COMMISSION: `}`;

  const isEffectiveOnRequest = effectiveBookingBuilder.response.totals.oneOrMoreItemsOnRequest;

  const isEffectiveDiscounted =
    (effectiveBookingBuilder.response.totals.totalBeforeDiscountForPricedItemsCents || 0) >
    (effectiveBookingBuilder.response.totals.totalForPricedItemsCents || 0);

  const canBookLatest = latestBookingBuilder?.response?.canBeBooked === true || false;

  const hasTotalsChanged =
    props.build.latestBookingBuilderResponse?.totals.total !== props.build.initialBuildResponse.totals.total ||
    props.build.latestBookingBuilderResponse?.totals.oneOrMoreItemsOnRequest !==
      props.build.initialBuildResponse.totals.oneOrMoreItemsOnRequest;

  const hasOffersChanged =
    props.build.latestBookingBuilderResponse?.appliedOfferNames.join(',') !==
    props.build.initialBuildResponse.appliedOfferNames.join(',');

  const total = formatPrice(effectiveBookingBuilder.response?.totals.total);
  const totalBeforeDiscount = formatPrice(effectiveBookingBuilder.response?.totals.totalBeforeDiscount);

  const initialOffersString = initialBookingBuilder.response?.appliedOfferNames.join(', ');
  const latestOffersString = latestBookingBuilder?.response?.appliedOfferNames.join(', ') || '';

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [basketDeletingNetwork, setBasketDeletingNetwork] = useState(ENetworkRequestStatus.IDLE);

  const [addCommissionModalOpen, setAddCommissionModalOpen] = useState(false);
  const [itemCommissionUpdateNetwork, setItemCommissionUpdateNetwork] = useState(ENetworkRequestStatus.IDLE);

  const backendApi = makeBackendApi(props.selectedTaUserUuid as string | undefined);

  const handleDeleteItemClick = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleDeleteItemConfirmation = useCallback(async () => {
    setBasketDeletingNetwork(ENetworkRequestStatus.PENDING);
    try {
      await backendApi.removeFromBasket(props.build.uuid, props.actingOnBehalfOfUser?.uuid);
      setBasketDeletingNetwork(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Item removed from basket successfully`,
          options: { variant: 'success' },
        })
      );
      if (props.onDeleteBuild) {
        props.onDeleteBuild(props.build.uuid);
      }
      setConfirmationModalOpen(false);
    } catch (error) {
      setBasketDeletingNetwork(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Deleting Error. Please try later`,
          options: { variant: 'error' },
        })
      );
    }
    setConfirmationModalOpen(false);
  }, [backendApi, props.build.uuid]);

  const handleAddTACommissionModalStatus = useCallback((status: boolean) => {
    setAddCommissionModalOpen(status);
  }, []);

  const isBasketPageView = isNil(props.basketItemSharedTo);
  const isSharedToTa = props.basketItemSharedTo === 'ta';
  const openPhotoGalleryModal = useCallback(() => {
    setIsGalleryModalOpen(true);
  }, []);

  const closePhotoGalleryModal = useCallback(() => {
    setIsGalleryModalOpen(false);
  }, []);

  const showCommissionInfo = isBasketPageView || (isSharedToTa && hasCommission);

  return (
    <div
      id={`basket-item-${props.build.uuid}`}
      className={classNames('flex flex-col bg-gray-3 py-15px px-20px', {
        'outline outline-teal-100': props.isSelected,
      })}
    >
      {addCommissionModalOpen && (
        <AddTACommissionModal
          title={'Add commission'}
          onClose={() => handleAddTACommissionModalStatus(false)}
          currencySymbol={currencySymbol}
          marginAmount={commissionAmount}
          totalAmount={totalAmount}
          marginPercent={commissionPercent}
          isLoading={itemCommissionUpdateNetwork === ENetworkRequestStatus.PENDING}
          onSave={(calculatedAmount: number, marginPercentage: string) => {
            setItemCommissionUpdateNetwork(ENetworkRequestStatus.PENDING);
            backendApi
              .patchBasketBuildCommission(
                props.build.uuid,
                marginPercentage,
                status => status >= 200 && status <= 299,
                props.actingOnBehalfOfUser
              )
              .then(response => {
                setItemCommissionUpdateNetwork(ENetworkRequestStatus.SUCCESS);
                props.onUpdateBuildCommission && props.onUpdateBuildCommission(props.build.uuid, marginPercentage);
                handleAddTACommissionModalStatus(false);
                dispatch(
                  enqueueNotification({
                    message: 'Saved successfully',
                    options: { variant: 'success' },
                  })
                );
              })
              .catch(error => {
                setItemCommissionUpdateNetwork(ENetworkRequestStatus.ERROR);
                handleAddTACommissionModalStatus(false);
                dispatch(
                  enqueueNotification({
                    message: 'Saving Error. Please try later.',
                    options: { variant: 'error' },
                  })
                );
              });
          }}
        />
      )}
      {confirmationModalOpen && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-import-modal"
          title="You are about to remove an item from the basket."
          message={`Are you sure you want to continue?`}
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteItemConfirmation}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
          isConfirmLoading={false}
        />
      )}
      <div className={classNames('flex items-center justify-around gap-x-20px')}>
        {props.selectionType !== 'none' && (
          <span className="1st-col w-[25px] h-[25px]">
            {props.selectionType === 'multiple' && (
              <PureCheckbox
                onChange={() =>
                  canBookLatest && props.onSelectBuild && props.onSelectBuild(props.build.uuid, !props.isSelected)
                }
                checked={props.isSelected}
                className="w-[25px] h-[25px]"
              />
            )}
            {props.selectionType === 'single' && (
              <span className="block w-[25px] h-[25px]">
                <RadioButtonSingle
                  id={props.build.uuid}
                  onSelect={() => {
                    canBookLatest && props.onSelectBuild && props.onSelectBuild(props.build.uuid, !props.isSelected);
                  }}
                  isSelected={props.isSelected}
                  value={props.build.uuid}
                />
              </span>
            )}
          </span>
        )}
        <span
          onClick={openPhotoGalleryModal}
          className="2nd-col hotel-image self-stretch w-[100px] lg:w-[120px] xl:w-[175px] 2lx:w-[200px] max-h-[162px] bg-cover aspect-[11/19] hidden md:block relative cursor-pointer"
          style={{
            backgroundImage: `url(${props.build.hotelPhotos[0]?.url ?? ''})`,
          }}
        >
          {props.build.hotelPhotos.length >= 2 && (
            <span className="bg-black/50 p-[6px] text-white font-hurmegeometric-sans absolute bottom-2 right-2 pointer-events-none">
              +{props.build.hotelPhotos.length - 1} photos
            </span>
          )}
        </span>
        <span className="3rd-col flex-grow">
          <span className="flex flex-row justify-between mb-10px">
            <div>
              {/* human readable id */}
              <span className="block font-hurmegeometric-sans text-gray-80 text-[13px] mb-5px">
                <span className="uppercase font-bold">id. </span>
                <span>{props.build.humanReadableId || 'ID not available'}</span>
              </span>
              {/* stars rating */}
              <span className="flex items-center uppercase space-x-2 mb-5px">
                <SvgIcon IconComponent={Star} className="w-4 h-4 fill-brown-prime" />
                <span className="text-brown-prime text-12px leading-14px mt-3px">5 star</span>
              </span>
              {/* hotel name */}
              <span className="block font-noe-display text-brown-prime text-[20px] mb-5px">
                {props.build.initialBuildResponse.hotel.name}
              </span>

              {/* hotel region */}
              <span className="block font-hurmegeometric-sans text-brown-prime text-[14px] uppercase">
                {props.build.initialBuildResponse.hotel.region}
              </span>

              <FluidButton
                type="secondary"
                slim={true}
                onClick={() => {
                  openPhotoGalleryModal();
                }}
                className="md:hidden cursor-pointer text-12px px-[5px] py-[1px] font-hurmegeometric-sans mt-[6px] mb-[18px] text-brown-prime"
                textClassName="flex items-center space-x-2 text-13px font-normal"
              >
                <SvgIcon IconComponent={PictureIcon} className="fill-brown-prime" width="18px" height="18px" />
                <span className="ml-2">Hotel Gallery</span>
              </FluidButton>

              {/* date range, nights, guest count, room count */}
              <span className="flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-2 font-hurmegeometric-sans uppercase mb-10px">
                <span className="font-bold text-[13px] p-2px ">{dateRangeString}</span>

                <div className="flex items-center space-x-2">
                  <span className="bg-teal-20 text-13px p-2px">
                    {lengthOfStay} {lengthOfStay === 1 ? 'night' : 'nights'}
                  </span>
                  <span className="text-13px">{countString(guestCount, 'guest')}</span>
                  <span className="text-13px">{countString(accommodationCount, 'room')}</span>
                </div>
              </span>

              {/* transfer count and ground service count */}
              <span className="flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-2 font-hurmegeometric-sans uppercase mb-10px">
                <span className="text-13px">{countString(transferCount, 'transfer')}</span>
                <span className="text-13px">{countString(groundServiceCount, 'ground service')}</span>
              </span>

              {showCommissionInfo && (
                <div className="flex items-center space-x-2 font-hurmegeometric-sans uppercase">
                  {/* basket page view - they can add or view commission */}
                  {isBasketPageView && (
                    <>
                      <span
                        className="text-brown-prime underline cursor-pointer"
                        onClick={() => handleAddTACommissionModalStatus(true)}
                      >
                        {commissionButtonText}
                      </span>
                      <span className="text-13px">{commissionText}</span>
                    </>
                  )}
                  {/* shared to TA and it has a commission - they can VIEW the commission, not add or edit */}
                  {isSharedToTa && hasCommission && (
                    <>
                      <span className="text-brown-prime">COMISSION: </span>
                      <span className="text-13px">{commissionText}</span>
                    </>
                  )}
                  {/* shared to final client - nothing, final clients dont interact with commissions */}
                  {/* {isSharedToFinalClient && N/A } */}
                </div>
              )}

              {onHoldInfo.isOnHold && (
                <OnHoldInfoPrimary isSetValid={onHoldInfo.isSetValid} setDeadline={onHoldInfo.setDeadline}/>
              )}

            </div>
            <div className="flex flex-col space-y-5px">
              <span className="flex self-end items-center space-x-2">
                {/* various warning icons */}
                <div className="tool-tip icons flex space-x-2">
                  {/* if we can't book the latest, red triangle with errors */}
                  {!canBookLatest && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            This item is no longer available and cannot be booked.
                            <br />
                            {hasLatestBookingBuild && <span>Please edit or remove the item.</span>}
                            {!hasLatestBookingBuild && <span>Please remove the item.</span>}
                          </div>
                        );
                      }}
                      position="left"
                      notRelativeClassName={true}
                    >
                      <SvgIcon IconComponent={RejectedIcon} className="w-5 h-5 fill-red-95 mt-1" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the totals have change but the offers haven't */}
                  {canBookLatest && hasTotalsChanged && !hasOffersChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{initialTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{latestTotalCostString}</span>
                            </span>
                          </div>
                        );
                      }}
                      position="left"
                      notRelativeClassName={true}
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the offers have changed but the totals haven't */}
                  {canBookLatest && hasOffersChanged && !hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The offers have changed</span>
                            <span className="block">Previous offers: {initialOffersString}</span>
                            <span className="block">New offers: {latestOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                      notRelativeClassName={true}
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the offers have changed AND the totals have changed */}
                  {canBookLatest && hasOffersChanged && hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{initialTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{latestTotalCostString}</span>
                            </span>
                            <br />
                            <span className="block">Previous offers: {initialOffersString}</span>
                            <span className="block">New offers: {latestOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                      notRelativeClassName={true}
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}
                </div>
                
                {/* if we do have a latest booking build, they can edit it */}
                {isBasketPageView && hasLatestBookingBuild && (
                  <button
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={props.onEdit}
                  >
                    <SvgIcon IconComponent={PencilIcon} width="18px" height="18px" className="-ml-[2px]" />
                  </button>
                )}

                {/* if shared to a TA, they can directly copy from an icon */}
                {isSharedToTa && props.onCopy && (
                  <button
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={props.onCopy}
                  >
                    <SvgIcon IconComponent={CopyIcon} width="20px" height="20px" className="fill-brown-prime -ml-3px" />
                  </button>
                )}

                {/* they can always delete */}
                {isBasketPageView && (
                  <button
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={handleDeleteItemClick}
                  >
                    <SvgIcon IconComponent={TrashIcon} width="12px" height="14px" />
                  </button>
                )}
              </span>

              <RateTypeBadge rateType={rateType} />

              {/* if we ARE on request, regardless of discount, show as on request */}
              {isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="font-hurmegeometric-sans text-black text-15px leading-20px font-[600] text-right">
                    On Request
                  </span>
                </div>
              ) : null}

              {/* if we're NOT discounted and NOT on request, just show a single total */}
              {!isEffectiveDiscounted && !isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-black text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                </div>
              ) : null}

              {/* if we ARE discounted but NOT on request, show the total and total before discount */}
              {isEffectiveDiscounted && !isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-red-92 text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-gray-80 text-15px leading-18px text-right line-through">
                    {currencySymbol}
                    {formatPrice(totalBeforeDiscount)}
                  </span>
                </div>
              ) : null}
            </div>
          </span>
        </span>
      </div>

      {/* hotel gallery button and expandable area button */}
      <div className="flex items-center self-end space-x-2">
        {/* theres always a button to show the expandable area */}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames(
            'cursor-pointer flex justify-around items-center h-6 w-[100px] bg-transparent hover:bg-gray-20 active:bg-gray-40 border-none outline-none'
          )}
        >
          <SvgIcon
            IconComponent={ArrowDown}
            className={classNames('transition-transform w-[14px] fill-brown-prime stroke-1', {
              'rotate-180': isExpanded,
            })}
          />
          {!isExpanded && (
            <span className="text-13px font-hurmegeometric-sans underline text-brown-prime">See More</span>
          )}
          {isExpanded && (
            <span className="text-13px font-hurmegeometric-sans underline text-brown-prime">See Less</span>
          )}
        </button>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0} className={classNames('accordion-area', {})}>
        <BasketItemExpandableInfo
          key={props.build.uuid}
          booking={effectiveBookingBuilder}
          lodgingSummaries={BookingBuilderUtils.generateLodgingSummariesFromBooking(t, effectiveBookingBuilder)}
          build={props.build}
          onHoldInfo={onHoldInfo}
        />
      </AnimateHeight>

      {isGalleryModalOpen && props.build?.hotelPhotos && (
        <BasketHotelPhotoGalleryModal
          title={props.build.initialBuildResponse.hotel.name}
          photos={props.build.hotelPhotos}
          onClose={closePhotoGalleryModal}
        />
      )}
    </div>
  );
};
