import React from 'react';
import classnames from 'classnames';
import { warning, approved, rejected } from 'ui/Icons';

export enum EBannerType {
  APPROVED = 'APPROVED',
  WARNING = 'WARNING',
  REJECTED = 'REJECTED',
}

export const getBannerIconByType = (type: EBannerType) => {
  const iconMapping = {
    [EBannerType.APPROVED]: approved,
    [EBannerType.WARNING]: warning,
  }
  return iconMapping[type];
}

interface IBannerProps {
  type: EBannerType;
  className?: string;
  children: React.ReactNode;
}

export const Banner: React.FC<IBannerProps> = React.memo(props => {
  const bannerClassName = classnames(
    'banner flex text-base font-pt-sans py-[10px] px-[20px] border border-solid rounded', props.className,
    {
      'bg-green-25 border-green-50': props.type === EBannerType.APPROVED,
      'bg-yellow-20 border-yellow-50': props.type === EBannerType.WARNING,
      'bg-red-25 border-red-95': props.type === EBannerType.REJECTED,
    }
  );

  return (
    <div className={bannerClassName}>
      {props.children}
    </div>
  );
});
