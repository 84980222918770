import { BackendApiService, BookingBuilder } from 'services/BackendApi';
import {
  IBasketBuild,
  IBasketBuildL2,
  IBasketBuildL3,
  IBasketBuildL4,
  ISharedCompanyMembership,
} from 'services/BackendApi/types/Basket';
import { notNullOrUndefined } from 'utils/array';
import * as _ from 'lodash-es';
import { ICompanyMembership } from 'services/BookingManagerApi';
import { AxiosInstance } from 'axios';
import { AxiosWrapper } from 'services/AxiosWrapper';

export const getHotelImage = (build: IBasketBuildL2) => {
  return build.hotelPhotoUrl;
};

export const convertBasketBuildToBookingBuilderFormat = (
  basketBuild: IBasketBuild | IBasketBuildL2 | IBasketBuildL3 | IBasketBuildL4,
  useLatestBookingBuild = false
): BookingBuilder => {
  return {
    bookingErrors: useLatestBookingBuild
      ? (basketBuild as IBasketBuildL4).latestBookingBuilderResponse?.errors || []
      : basketBuild.initialBuildResponse.errors,
    request: basketBuild.buildRequest,
    response: useLatestBookingBuild
      ? (basketBuild as IBasketBuildL4).latestBookingBuilderResponse!
      : basketBuild.initialBuildResponse,
  };
};

/**
 * Given some basket builds, get the level 2 builds
 * e.g works out the hotel photos and company memberships
 */
export const getLevel2BasketBuilds = async ({
  builds,
  sharedCompanyMemberships,
  backendApi,
}: {
  builds: IBasketBuild[];
  sharedCompanyMemberships?: ISharedCompanyMembership[];
  backendApi?: BackendApiService<AxiosWrapper<AxiosInstance>>;
}): Promise<IBasketBuildL2[]> => {
  // if we've not supplied shared memberships, we need to look up the memberships
  let memberships: ICompanyMembership[] = [];
  if (!sharedCompanyMemberships) {
    const companyMembershipUuids: string[] = _.uniq(
      builds.map(build => build.companyMembershipUuid).filter(notNullOrUndefined)
    );
    if (companyMembershipUuids.length >= 1 && backendApi) {
      const membershipsResponse = await backendApi.getCompanyMemberships(companyMembershipUuids);
      memberships = membershipsResponse.data.data;
    }
  }

  // build the level 2 builds - hotel photos and company memberships
  const buildsLevel2 = builds.map(build => {
    const hotelUploadsSet = build.initialBuildResponse.uploads.filter(
      upload => upload.ownerUuid === build.initialBuildResponse.hotel.uuid
    );
    let hotelPhoto = hotelUploadsSet.find(upload => upload.tag === 'featuredPhoto');
    const hotelPhotosWithoutFeaturedPhoto = hotelUploadsSet.filter(upload => ['photo'].includes(upload.tag)) || [];
    const hotelPhotos = [hotelPhoto, ...hotelPhotosWithoutFeaturedPhoto]
      .filter(Boolean)
      .map(item => ({ displayName: item?.displayName, url: item?.url }));
    if (!hotelPhoto) {
      const hotelPhotoSet = hotelUploadsSet.filter(upload => upload.tag === 'photo') || [];
      // Criteria: choose the most recent
      hotelPhotoSet.sort((a, b) => {
        return new Date(a.createdAt).getMilliseconds() - new Date(b.createdAt).getMilliseconds();
      });
      hotelPhoto = hotelPhotoSet[0];
    }

    // also see if it has a matching company membership
    let companyMembership: ISharedCompanyMembership | undefined;

    // if we have a shared company memberships, we need to find the matching one
    if (sharedCompanyMemberships && sharedCompanyMemberships.length >= 1) {
      companyMembership = sharedCompanyMemberships.find(m => m.uuid === build.companyMembershipUuid);
    } else {
      // otherwise, see if theres one from the memberships
      companyMembership = memberships.find(m => m.uuid === build.companyMembershipUuid);
    }

    return {
      ...build,
      hotelPhotoUrl: hotelPhoto?.url,
      hotelPhotos,
      uploads: build.initialBuildResponse.uploads,
      companyMembership,
    };
  });

  return buildsLevel2 as IBasketBuildL2[];
};

export const isOnHold = (basketBuild: IBasketBuildL4): boolean => {
  const now = new Date();
  return basketBuild.holdSupplierResponse?.externalBookings
    .some(x => new Date(x.holdDeadline) > now) || false;
};
