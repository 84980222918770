import { useMemo } from 'react';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { ELiveRatesExternalSystem } from 'services/BackendApi/types/LiveRatesInternal';

const options = [
  {
    value: ELiveRatesExternalSystem.DERBYSOFT,
    label: 'DerbySoft',
  },
  {
    value: ELiveRatesExternalSystem.ILLUSIONS,
    label: 'Illusions',
  },
  {
    value: ELiveRatesExternalSystem.JUMEIRAH,
    label: 'Jumeirah',
  },
  {
    value: ELiveRatesExternalSystem.MOCK,
    label: 'Mock',
  },
];

export const useSupplierList = () => {
  const { dynamicParameters } = useDynamicParameters();

  const validOptions = useMemo(() =>
    options
      .filter(x => dynamicParameters.LIVE_RATE_SUPPLIERS_LIST?.includes(x.value)),
    [dynamicParameters.LIVE_RATE_SUPPLIERS_LIST]
  );

  return { supplierList: validOptions };
};
