import React from 'react';
import Helmet from 'react-helmet';
import { ItineraryHero } from './ItineraryHero';
import { ItineraryHeader } from './ItineraryHeader';

export const ItineraryPage = () => {
  return (
    <>
      <Helmet title="Itinerary" />

      <ItineraryHeader />
      <div className="container w-full min-w-full mx-auto">
        <ItineraryHero
          backgroundImage="https://placehold.co/600x400"
          title="South Africa"
          subtitles={['subtitle line one', 'this is subtitle line 2']}
        />
      </div>
    </>
  );
};
